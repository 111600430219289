<template>
  <b-modal
    id="import-xml-tiss-success"
    hide-footer
    centered
    @show="onShow"
    size="lg"
    title="Faturamento TISS"
    modal-class="modal-custom"
  >
    <div class="row my-3 text-right">
      <div class="col-md-12">
        <div class="d-flex">
          <p class="text-left p-1">
            Seu arquivo XML no faturamento TISS foi carregado com sucesso! <br>
            Deseja ser redirecionado para visualizar?
          </p>
        </div>
      </div>
    </div>

    <div class="row my-3 row-with-border" v-for="batch, index in batches" :key="index">
      <div class="col-md-12">
        <div class="d-flex">
          <label class="text-light-blue">Convênio: <span class="text-gray">{{ batch.tiss_invoice?.clinic_health_plan?.name || '-' }}</span></label>
          <label class="text-light-blue">Tipo: <span class="text-gray">{{ getTypes(batch?.type) }}</span></label>
          <label class="text-light-blue">Versão TISS: <span class="text-gray">{{ batch.xml_version }}</span></label>
          <label class="text-light-blue">Lote Nº: <span class="text-gray">{{ batch?.number }}</span></label>
          <label class="text-light-blue">Fatura Nº: <span class="text-gray">{{ batch?.tiss_invoice.number }}</span></label>
        </div>
      </div>
      <div class="col-md-3">
        <label class="p-2 bg-gray text-blue">
          {{ parseNumberToMoney(batch.items_total) }} <span class="legend">Faturado</span>
        </label>        
      </div>
      <div class="col-md-3">
        <label class="p-2 bg-gray text-blue">
          {{ batch.guides_count }} <span class="legend">Qtde. de guias</span>
        </label>        
      </div>
      <div class="col-md-3">
        <label class="p-2 bg-gray text-green">
          {{ parseNumberToMoney(batch.items_total - batch.items_paid) }} <span class="legend">A receber</span>
        </label>        
      </div>
      <div class="col-md-3">
        <label class="p-2 bg-gray text-red">
          {{ parseNumberToMoney(batch.items_glossed) }} <span class="legend">Glosado</span>
        </label>        
      </div>
    </div>

    <div class="row my-3 text-right">
      <div class="col-md-12">
        <b-button class="mt-2 mr-4" variant="outline-danger" @click="hideModal">Cancelar</b-button>
        <b-button class="mt-2" variant="primary" @click="redirectToSentLots">Visualizar</b-button>
      </div>
    </div>

  </b-modal>
</template>

<script>
import '@/assets/scss/modal-custom.scss';
import { mapState, mapActions } from 'vuex';
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { getTypes } from '@/modules/tiss/manageGuides/utils/guideHelper'

export default {
  components: {
  },
  computed: {
    ...mapState({
      attendance: state => state.attendance.attendance,
    })
  },
  data() {
    return {
      parseNumberToMoney,
      getTypes
    }
  },
  props: {
    batches: Array
  },
  methods: {
    ...mapActions('attendance', ['closeAttendance', 'clearAttendance']),
    onShow() {
      this.pauseReason = 'Consulta em andamento'
    },
    closeModal() {
      this.$bvModal.hide('import-xml-tiss-success')
    },
    hideModal() {
      this.$bvModal.hide('import-xml-tiss-success')
    },
    redirectToSentLots() {
      window.location.href = '/faturamento-tiss/lotes-enviados?id=' + this.batches[0].id;
    }
  }
}
</script>

<style scoped lang="scss">
.emphasis {
  font-weight: 600;
  color: var(--content-brand, #305BF2);
}

label {
  font-size: 12px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: left;
  width: 100%;
  padding: 5px;
}

.bg-gray {
  background: var(--surface-secondary, #F8F8FD);
  border-radius: 4px;
}

.legend {
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  color: var(--border-hover, #A4B1DF);
  float: right;
  margin: 0px 5px;
}

.text-light-blue {
  color: #0E3DE2 !important;
}

.text-blue {
  color: #0C1D59 !important;
}

.text-green {
  color: #097C53 !important;
}

.text-red {
  color: #F63220 !important;
}

.text-gray {
  color: #525C7A !important;
}

.row-with-border {
  border: 1px solid #C6CEEB;
  border-radius: 8px;
  padding: 10px;
}
</style>
