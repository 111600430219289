<template>
  <b-modal
    id="attached-document-modal"
    hide-header
    hide-footer
    centered
    size="lg"
  >
    <div class="header">
      <div class="header-content">
        <p>Documentos anexados</p>
        <Close class="close-icon" @click="closeModal" />
      </div>
    </div>
    <div class="body">
      <div class="body-content">
        <div class="container">
          <div class="d-flex justify-content-between">
            {{documentName}}      
            <b-button
              variant="link"
              class="openDocsTab"
              @click="openDocTab()"
            >
              Abrir em nova aba
              <v-export class="icon" />
            </b-button>
          </div>
          <div v-if="url" class="documents">
            <iframe
              class="pdf-viewer"
              title="Documentos da anexados"
              :src="url"
            />
          </div>
          <b-skeleton-img v-else></b-skeleton-img>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-content">
        <button @click="closeModal">Fechar</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import Export from '@/assets/icons/export.svg'

export default {
  name: 'DocumentModal',
  props: {
    url: String,
    documentName: String
  },
  components: {
    Close,
    'v-export': Export,
  },
  methods: {    
    closeModal() {
      this.$bvModal.hide('attached-document-modal')
      this.$emit('close-modal')
    },
    async getDocUrl(path){
      const loading = this.$loading.show()
      try {
        const res = await this.api.getDocTemporaryUrl(path)
        return res.data
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    async openDocTab() {
      window.open(this.url, '_blank')
    },
  },
  watch: {
    url: {
      async handler(value) {
        if (value.length) {
          this.$bvModal.hide('print-history-modal');
          window.open(value, '_blank')
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
#attached-document-modal {
  font-family: 'Nunito Sans';
  font-style: normal;
  color: var(--type-active);

  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);

    .modal-body {
      padding: 0;
      .header {
        border-bottom: 1px solid var(--neutral-200);

        .header-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 24px;

          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
          }

          .close-icon {
            fill: var(--neutral-500);
            height: 24px;
            width: 24px;
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column;

        .body-content {
          display: flex;
          flex-direction: column;

          .container {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 500px;
            padding: 24px;

            .openDocsTab {
              display: flex;
              flex-direction: row;
              justify-content: end;
              align-items: center;
              padding-bottom: 10px;

              .icon {
                border: none;
                stroke: none;
                margin-left: 8px;
              }
            }

            .documents {
              width: 100%;
              height: 500px;

              .pdf-viewer {
                width: 100%;
                height: 100%;
                border: none !important;
              }
            }
          }
        }
      }

      .footer {
        .footer-content {
          display: flex;
          justify-content: right;
          margin: 24px;

          button {
            width: auto;
            height: auto;
            padding: 14px 16px;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            border-radius: 8px;
            background-color: var(--blue-500);
            color: var(--neutral-000);
          }
        }
      }
    }
  }
}
</style>