<template>
  <b-modal
    id="import-xml-tiss-error"
    hide-footer
    centered
    @show="onShow"
    title="Erro de importação do XML"
    modal-class="modal-custom"
  >
    <div class="row my-3 text-right">
      <div class="col-md-12">
        <div class="d-flex">
          <div class="m-1">
            <ErrorIcon
              class="icon m-auto p-2"
            />
          </div>
          <p class="text-left p-1" v-for="(file, indx) in resume" :key="indx">
            A importação do arquivo <span class="emphasis">{{ file.filename }}</span> falhou!
            <br>
            Motivo: <span class="emphasis">{{ file.reason  }}</span>. 
          </p>
        </div>
      </div>
    </div>

    <div class="row my-3 text-right">
      <div class="col-md-12">
        <b-button class="mt-2" variant="primary" @click="closeModal">Concluir</b-button>
      </div>
    </div>

  </b-modal>
</template>

<script>
import '@/assets/scss/modal-custom.scss';
import { mapState, mapActions } from 'vuex';
import ErrorIcon from '@/assets/icons/error-icon.svg'

export default {
  components: {
    ErrorIcon
  },
  computed: {
    ...mapState({
      attendance: state => state.attendance.attendance,
    })
  },
  data() {
    return {
      pauseReason: null,
    }
  },
  props: {
    resume: Array
  },
  methods: {
    ...mapActions('attendance', ['closeAttendance', 'clearAttendance']),
    onShow() {
      this.pauseReason = 'Consulta em andamento'
    },
    closeModal() {
      this.$bvModal.hide('import-xml-tiss-error')
    },
  }
}
</script>

<style scoped lang="scss">
.emphasis {
  font-weight: 600;
  color: var(--content-brand, #305BF2);
}
</style>
